import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFirestore, collection, getDocs} from "firebase/firestore";
import firebaseConfig from "../firebase";
import styles from "../styles/components/Livepuja.module.css";
import { initializeApp } from "firebase/app";
import { Link } from "react-router-dom";


const PujaCard = ({ id, title, imageUrl, show }) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <div className={styles.card}>
      <Link to={"./live/" + id}>
        <img src={imageUrl} alt={t(title)} className={styles.puja_image} />
      </Link>
      <div className={styles.list_details}>
        <h3 className={styles.puja_title}>{t(title)}</h3>
      </div>
    </div>
  );
};



function LivePuja() {
  const [livePuja, setLivePujas] = useState([]);
  // eslint-disable-next-line
  const [language, setLanguage] = useState("en");
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {

    const fetchLivePujas = async () => {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const pujasCollection = collection(db, "livepuja");
      const pujasSnapshot = await getDocs(pujasCollection);
      const pujasData = pujasSnapshot.docs.map((doc) => doc.data())
      setLivePujas(pujasData)
    };    
    fetchLivePujas();
  }, []);

  return livePuja && (
    <div className={styles.bookpuja}>
      <div className={styles.list}>
        {livePuja.map((puja) => (
          <PujaCard
            key={puja.offeringId}
            id={puja.offeringId}
            imageUrl={puja.image}
            show={true}
          />
        ))}
      </div>
    </div>
  );
}

export default LivePuja;
