import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styles from "../../styles/screens/Ayodhya/Ayodhya.module.css";
import temple from "../../assets/ic_temple.png";
import { useTranslation } from "react-i18next";
import PujaCard from "../../components/PujaCard";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, doc } from "firebase/firestore";
import firebaseConfig from "../../firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Ayodhya() {
  const { id } = useParams();
  const [pujas, setPujas] = useState([]);
  const [language, setLanguage] = useState("en");
  const [category, setCategory] = useState('');
  const { t, i18n } = useTranslation();

  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited: Ayodhya Page");

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchPujas = async () => {
      const pujasCollection = collection(db, "poojaplaces", id, "offerings");
      const pujaSnapshot = await getDocs(pujasCollection);
      if (!pujaSnapshot.empty) {
        const offeringsSnapshot = await getDocs(pujasCollection);
        const offeringsData = offeringsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            offeringId: data.offeringId,
            name: language === "en" ? data.name?.en : data.name?.hi,
            path: data.offeringName,
            price: data.price?.en,
            rating: data.rating,
            trending: data.trending,
            booked_by: data.booked_by,
            benefits: data.benefit_label,
            discounted_price: data.price?.discounted_price,
            imageUrl: (Array.isArray(data.image)) ? data.image[0] : data.image,
          };
        });
        setPujas(offeringsData);
      }
    };

    fetchPujas();
  }, [id, language]);

  useEffect(() => {
    const fetchCategory = async (id) => {
      try {
        const docRef = doc(db, "poojaplaces", id);
        const docSnapshot = await getDoc(docRef);
        
        if (docSnapshot.exists()) {
          const category = docSnapshot.get("place");
          const name = language === "en" ? category.en : category.hi;
          setCategory(name);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };
    
    const docId = id;
    fetchCategory(docId);
  }, [id, language]);

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <ToastContainer />
      <div className={styles.header}>
        <div className={styles.icon_text_wrapper}>
          <img src={temple} alt="Puja Book Icon" className={styles.icon} />
          <h1 className={styles.title}>{t(category)}</h1>
        </div>
        <p className={styles.subtitle}>
          {t("Select a puja of your choice for divine blessings")}
        </p>
      </div>
      <div className={styles.list}>
        {pujas.map((puja) => (
          <PujaCard
            key={puja.id}
            id={puja.offeringId}
            name={puja.name}
            price={puja.price}
            group={id}
            path={puja.path}
            rating={puja.rating}
            trending={puja.trending}
            booked_by={puja.booked_by}
            benefits={language === "en" ? puja.benefits?.en : puja.benefits?.hi}
            discounted_price={
              puja.discounted_price ? puja.discounted_price : -1
            }
            imageUrl={puja.imageUrl}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Ayodhya;
