import logo from "../../assets/logo.png";
const key_id = "rzp_live_vErndEFnkEHQsN";

const openRazorpay = async (
  group,
  orderId,
  pujaPrice,
  id,
  phone,
  description,
  email,
  name
) => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  console.log(name)

  script.onload = () => {
    console.log(name);
    const options = {
      key_id: key_id,
      order_id: orderId,
      name: "Dharmik App",
      description: description,
      image: logo,
      prefill: {
        name: name[0],
        email: email === "" ? "contact@dharmik.in" : email,
        contact: phone,
      },
      notes: "Price = " + pujaPrice,
      callback_url:
        "https://dharmikapp.in/payment/result/" +
        group +
        "/" +
        id +
        "/" +
        orderId,
      cancel_url:
        "https://dharmikapp.in/payment/result/" +
        group +
        "/" +
        id +
        "/" +
        orderId,
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

export default openRazorpay;
