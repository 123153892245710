import React, { useState, useEffect } from "react";
import styles from "../styles/components/Customer.module.css";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import firebaseConfig from "../firebase";

import temple from "../assets/ic_temple.png";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function isMP4(url) {
  const extension = url.split('.').pop().toLowerCase();
  return extension === 'mp4';
}


function Customer() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchReviews = async() => {
      try {
        const reviewsDoc = doc(db, 'reviews', 'homepage');
        const reviewDocSnap = await getDoc(reviewsDoc);
        const reviewData = reviewDocSnap.data();
        console.log('REVIEW DATA: ', reviewData.reviews);
        setReviews(reviewData.reviews);

      } catch(error) {
        console.log('Error in fetching review doc in homepage: ', error);
      }
    };
    fetchReviews();
  }, []);

  const nextCustomer = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevCustomer = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length
    );
  };

  const maxItemsVisible = 3;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
      <div className={styles.cardHeader}>
        <div className={styles.icon_text_wrapper}>
          <img src={temple} alt="Puja Book Icon" className={styles.icon}></img>
          <h1 className={styles.title}>{t("Our Customer Says")}</h1>
        </div>
        <p className={styles.bold_text}>{t("Listen to our customers tell you more about us !")}</p>
      </div>
      </div>
      <div className={styles.cardContainer}>
        {reviews.slice(currentIndex, currentIndex + maxItemsVisible).map((customer) => (
          <div className={styles.card} key={customer.id}>
            {isMP4(customer.image) === true ? (
              <video className={styles.image} controls muted>
                <source
                  src={customer.image}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                src={customer.image}
                alt={language === "en" ? customer.name : customer.name}
                className={styles.image}
              />
            )}
            <div className={styles.textContainer}>
              <h3 className={styles.name}>
                {language === "en" ? customer.name : customer.name}
              </h3>
              <h4 className={styles.location}>
                {language === "en"
                  ? customer.location
                  : customer.location}
              </h4>
              <p className={styles.testimonialText}>
                {language === "en"
                  ? customer.comment
                  : customer.comment}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.navigation}>
        <button disabled={currentIndex === 0} className={styles.navigation_button} onClick={prevCustomer}>
          {"<"}
        </button>
        <button disabled={currentIndex >= reviews.length - maxItemsVisible} className={styles.navigation_button} onClick={nextCustomer}>
          {">"}
        </button>
      </div>
    </div>
  );
}

export default Customer;
