import React from "react";
import NavBar from "../components/Navbar";
import NotFoundImage from "../assets/404.png";
import Footer from "../components/Footer";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useTranslation } from "react-i18next";

const Page404 = () => {
  const { t } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited 404 Page");

  return (
    <div>
      <NavBar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff1ea",
          height: "100vh",
        }}
      >
        <img src={NotFoundImage} alt={t("404 Error")} />
        <h1>{t("Page Not Found")}</h1>
        <a href="/" style={{ textDecoration: "none", color: "#ff6200" }}>
          {t("Go back to homepage")}
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Page404;
