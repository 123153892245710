import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "../styles/components/Download.module.css";

import GoogleImage from "../assets/google.png";
import AppleImage from "../assets/apple.svg";
import temple from "../assets/ic_temple.png";

function Download() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.explore}>
      <div className={styles.header}>
        <div className={styles.icon_text_wrapper}>
          <img src={temple} alt="Puja Book Icon" className={styles.icon}></img>
          <h2 className={styles.title}>{t("Download the App Now")}</h2>
        </div>
      </div>
      <div className={styles.list}>
        <div
          className={styles.card}
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=in.gharkamandir.gharkamandir&pli=1"
            )
          }
        >
          <img src={GoogleImage} alt={"Google"} className={styles.gimage} />
        </div>
        <div
          className={styles.card}
          onClick={() => navigate("/join-waitlist/ios-app")}
        >
          <img src={AppleImage} alt={"Apple"} className={styles.aimage} />
        </div>
      </div>
    </div>
  );
}

export default Download;
