import React from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Privacypolicy.module.css";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div>
      <NavBar />
      <div className={styles.privacy_policy_text}>
        <h1>{t("Dharmik App Terms of Use")}</h1>
        <p>
          <strong>{t("termsAndConditions.notice")}</strong>
        </p>

        <p>
          <strong>{t("termsAndConditions.introductionTitle")}</strong>
        </p>
        <p>{t("termsAndConditions.introductionContent")}</p>
        <p>{t("termsAndConditions.section1_2")}</p>
        <p>{t("termsAndConditions.section1_3")}</p>
        <p>{t("termsAndConditions.section1_4")}</p>

        <p>
          <strong>{t("termsAndConditions.overviewTitle")}</strong>
        </p>
        <p>{t("termsAndConditions.overviewContent")}</p>

        <p>
          <strong>{t("termsAndConditions.acceptanceTitle")}</strong>
        </p>
        <p>{t("termsAndConditions.acceptanceContent")}</p>

        <p>
          <strong>{t("termsAndConditions.accessTitle")}</strong>
        </p>
        <p>{t("termsAndConditions.accessContent1")}</p>
        <p>{t("termsAndConditions.accessContent2")}</p>

        <p>
          <strong>
            {t("termsAndConditions.registrationAndEligibilityTitle")}
          </strong>
        </p>
        <p>{t("termsAndConditions.registrationAndEligibilityContent1")}</p>
        <p>{t("termsAndConditions.registrationAndEligibilityContent2")}</p>
        <p>{t("termsAndConditions.registrationAndEligibilityContent3")}</p>
        <p>{t("termsAndConditions.registrationAndEligibilityContent4")}</p>
        <p>{t("termsAndConditions.registrationAndEligibilityContent5")}</p>

        <p>
          <strong>
            {t("termsAndConditions.registrationAndEligibilityTitle")}
          </strong>
        </p>
        <p>
          <strong>{t("termsAndConditions.yourResponsibilitiesTitle")}</strong>
        </p>
        <p>{t("termsAndConditions.yourResponsibilitiesIntro")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent1")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent2")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent3")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent4")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent5")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent6")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent10")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent11")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent12")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent14")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent15")}</p>
        <p>{t("termsAndConditions.yourResponsibilitiesContent16")}</p>

        <p>
          <strong>{t("termsAndConditions.paymentTermsTitle")}</strong>
        </p>
        <p>{t("termsAndConditions.paymentTermsContent")}</p>

        <p>
          <strong>
            {t("termsAndConditions.cancellationAndRefundPolicyTitle")}
          </strong>
        </p>
        <p>{t("termsAndConditions.cancellationAndRefundPolicyContent1")}</p>
        <p>{t("termsAndConditions.cancellationAndRefundPolicyContent2")}</p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
