import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs} from "firebase/firestore";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import styles from "../styles/components/LiveTimer.module.css";

const LiveTimer = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval( async() => {
      const currentDate = new Date();
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const pujasCollection = collection(db, "livepuja");
      const pujasSnapshot = await getDocs(pujasCollection);
      const pujasData = pujasSnapshot.docs.map((doc) => doc.data())
      const parts = pujasData[0].date.split("-");
      const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
      const pujaDate = new Date(formattedDate); // Change this to your puja date
      const timeDiff = pujaDate.getTime() - currentDate.getTime();
      const remainingSeconds = Math.floor(timeDiff / 1000);

      setTimer(remainingSeconds > 0 ? remainingSeconds : 0);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };
  
  return (
    <div className={styles.timer}>
      <div className={styles.timeContainer}>
      <div className={styles.timeBox}>
          <span className={styles.timeText} style={{ color: 'white' }}>{Math.floor(timer / 86400)}</span>
          <span className={styles.timeLabel}>Days</span>
        </div>
        <div className={styles.timeBox}>
          <span className={styles.timeText} style={{ color: 'white' }}>{formatTime(timer).substring(0, 2)}</span>
          <span className={styles.timeLabel}>Hours</span>
        </div>
        <div className={styles.timeBox}>
          <span className={styles.timeText} style={{ color: 'white' }}>{formatTime(timer).substring(3, 5)}</span>
          <span className={styles.timeLabel}>Min</span>
        </div>
        <div className={styles.timeBox}>
          <span className={styles.timeText} style={{ color: 'white' }}>{formatTime(timer).substring(6, 8)}</span>
          <span className={styles.timeLabel}>Sec</span>
        </div>
      </div>
    </div>
  ); 
  
};

export default LiveTimer;
