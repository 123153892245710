import React, { useState } from "react";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/ContactUs.module.css";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

import firebaseConfig from "../firebase";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const ContactUs = () => {
  const [name, setName] = useState(
    JSON.parse(localStorage.getItem("name"))
      ? JSON.parse(localStorage.getItem("name"))
      : ""
  );
  const [phone, setPhone] = useState(
    JSON.parse(localStorage.getItem("phoneNumber"))
      ? JSON.parse(localStorage.getItem("phoneNumber"))
      : ""
  );
  const { t } = useTranslation();

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited: Contact Us Page");

  async function writeWaitlistData(name, phone) {
    try {
      const docRef = await addDoc(collection(db, "waitlist"), {
        name,
        phone,
        feature_request: "Contacting us through website",
        time: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      toast.success("Message sent succesfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await writeWaitlistData(name, phone);
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  return (
    <div>
      <NavBar />
      <div className={styles.contactContainer}>
        <ToastContainer />
        <form onSubmit={handleSubmit} className={styles.contactForm}>
          <h2>{t("Thanks for getting in touch!")}</h2>
          <h3>
            {t(
              "Please provide the below details and we will get back to you soon"
            )}
          </h3>

          <label htmlFor="name">{t("Name")}</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => handleInputChange(e, setName)}
            required
          />

          <label htmlFor="phone">{t("Phone Number")}</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => handleInputChange(e, setPhone)}
          />

          <button type="submit" className={styles.submitButton}>
            {t("Contact Us")}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
