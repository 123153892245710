import { useEffect } from 'react';

const ChatWidget = () => {
  useEffect(() => {
    const url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?81281';
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    const options = {
      "enabled": true,
      "chatButtonSetting": {
        "backgroundColor": "#eb894e",
        "ctaText": "Chat with us",
        "borderRadius": "25",
        "marginLeft": "0",
        "marginRight": "20",
        "marginBottom": "20",
        "ctaIconWATI": false,
        "position": "right"
      },
      "brandSetting": {
        "brandName": "Dharmik",
        "brandSubTitle": "We help you book puja and get prasad delivered to your door.",
        "brandImg": "https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png",
        "welcomeText": "Hi there!\nHow can we help you?",
        "messageText": "Hello, %0A I have a question about {{page_link}}",
        "backgroundColor": "#eb894e",
        "ctaText": "Chat with us",
        "borderRadius": "25",
        "autoShow": false,
        "phoneNumber": "919258412314"
      }
    };
    s.onload = () => {
      window.CreateWhatsappChatWidget(options);
    };
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);

    return () => {
      // Cleanup
      x.parentNode.removeChild(s);
    };
  }, []);

  return null;
};

export default ChatWidget;
