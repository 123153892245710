import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import firebaseConfig from "../firebase";
import styles from "../styles/components/Bookpuja.module.css";
import temple from "../assets/ic_temple.png";

const PujaCard = ({ id, title, imageUrl, offerings, show }) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <div className={styles.card}>
      <Link to={"./puja/" + id}>
        <img src={imageUrl} alt={t(title)} className={styles.puja_image} />
      </Link>
      <div className={styles.list_details}>
        <h3 className={styles.puja_title}>{t(title)}</h3>
        <Link className={styles.book_button} to={"./puja/" + id}>
          {t("See")} {offerings} {t("pujas")}
        </Link>
      </div>
    </div>
  );
};

function BookPuja() {
  const [pujas, setPujas] = useState([]);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const fetchPujas = async () => {
      const pujasCollection = collection(db, "poojaplaces");
      const pujasSnapshot = await getDocs(pujasCollection);
      const pujasData = pujasSnapshot.docs
        .map((doc) => doc.data())
        .filter((puja) => puja.operational === true);
      setPujas(pujasData);
    };
    fetchPujas();
  }, []);

  return (
    <div className={styles.bookpuja}>
      <div className={styles.header}>
        <div className={styles.icon_text_wrapper}>
          <img src={temple} alt={t("Puja Book Icon")} className={styles.icon} />
          <h1 className={styles.title}>{t("Book Puja")}</h1>
        </div>
        <p>{t("Select the category of your choice to book a Puja")}</p>
      </div>
      <div className={styles.list}>
        {pujas.map((puja) => (
          <PujaCard
            key={puja.id}
            id={puja.id}
            title={language === "en" ? puja.place.en : puja.place.hi}
            imageUrl={puja.image}
            offerings={puja.number_offerings}
            show={puja.operational}
          />
        ))}
      </div>
    </div>
  );
}

export default BookPuja;
