async function getOrderId(pujaPrice) {
  const tmp = Math.round(pujaPrice).toString();
  const response = await fetch("https://createorder-ptmfa2eama-uc.a.run.app", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ amount: tmp * 100 }),
  });
  const data = await response.json();
  return data.id;
}

export default getOrderId;
