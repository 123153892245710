import React from "react";
import styles from "../../styles/screens/RefundPolicy.module.css";
import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

const ReferralPolicy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <NavBar />
      <div className={styles.container}>
        <h2>{t("referralPolicy.referralScheme")}</h2>
        <h3>{t("referralPolicy.earningsCalculationTitle")}</h3>
        <table>
          <thead>
            <tr>
              <th>{t("referralPolicy.downloads")}</th>
              <th>{t("referralPolicy.earnings")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("referralPolicy.downloadsRange1")}</td>
              <td>{t("referralPolicy.earningsRange1")}</td>
            </tr>
            <tr>
              <td>{t("referralPolicy.downloadsRange2")}</td>
              <td>{t("referralPolicy.earningsRange2")}</td>
            </tr>
            <tr>
              <td>{t("referralPolicy.downloadsRange3")}</td>
              <td>{t("referralPolicy.earningsRange3")}</td>
            </tr>
          </tbody>
        </table>
        <h3>{t("referralPolicy.pujaReferrals")}</h3>
        <p>{t("referralPolicy.pujaReferralsDescription")}</p>
        <h3>{t("referralPolicy.amountWithdrawPolicies")}</h3>
        <ul>
          <li>{t("referralPolicy.maxEarningPerMonth")}</li>
          <li>{t("referralPolicy.withdrawAmountPolicy")}</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default ReferralPolicy;
