import React, { useRef } from "react";
import styles from "../styles/screens/Homescreen.module.css";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import BookPuja from "../components/BookPuja";
import LivePuja from "../components/LivePuja";
import Livetimer from "../components/Livetimer";
import Customer from "../components/Customer";
import Download from "../components/Download";
import splashImage from "../assets/background/splash.png";
import ChatWidget from "../components/chatsupport/ChatWidget";

function HomeScreen() {
  const navigate = useNavigate();
  const bookPujaRef = useRef(null);
  const { t } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited Home Page");

  return (
    <div>
        <NavBar bookPujaRef={bookPujaRef} />
        <div className={styles.homepage}>
          <div className={styles.homepageImage}>
            <div className={styles.hp_content}>
              <div className={styles.hp_text_content}>
                <h1>{t("Book Puja at Mandir of your choice from home")}</h1>
                <p>
                  {t("We help you book puja and get prasad delivered to your door.")}
                </p>
                <div className={styles.buttons_group}>
                  <button
                    className={styles.white_button}
                    onClick={() => navigate("./contact-us")}
                  >
                    {t("Contact Us")}
                  </button>
                </div>
              </div>
              <img className={styles.splashImage} src={splashImage} alt="Splash"  />
            </div>
          </div>
        </div>
        <div>
        <h2 className={styles.specialHeader}>
          {t("Book our special weekly Puja")}
        </h2>
          <Livetimer />
        </div>
        <div>
          <LivePuja />
        </div>
        <div ref={bookPujaRef}>
          <BookPuja />
        </div>
      <Customer />
      <br></br>
      <br></br>
      <ChatWidget/>
      <Download />
      <Footer />
    </div>
  );
}

export default HomeScreen;
